div.ClientInfo {  
  padding: 4px;
}

div.ClientInfo strong {
  font-size:medium;
  font-weight: normal;
  padding-left: 8px;
  padding-right: 12px;
}

label.CI {
  font-size:medium;
  font-weight: normal;
  padding-left: 8px;
  padding-right: 12px;
  background-color: #888888;
  color: #ffffff;
}

.Select_address_find {
  font-size: 10pt;
  border: 0;
  margin: 4px;
  background-color: #ffffff;  
}
.Select_address_find:hover {
  background-color: #dddddd;
}

