div.Header {
  text-align: center;
  font-size: 18pt;
  font-weight: bold;
  color: #aaaaaa;
  width: 100%;  
  border-bottom: 1px solid #dddddd;
}

strong.trough {
  text-decoration: line-through;
  color: #e0c4c4;
  font-weight: normal;
}

div.Header span {
  color: #a12727;
  font-weight: bold;
  font-size: 20pt;
}