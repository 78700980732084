
div.ListDrug {
    text-align: center;
}

div.tableValues {
    overflow-y: auto;
    height: 70vh;
}

ul.listTable {
    width: 100%;
}

li.tableValue {
    border: 0;
    padding: 1px;
    margin: 0;
    text-align: left;
}
    
li.selected {
    background-color: #444444;
    color: #ffffff;
}

div.ListDrug strong {
    font-size:medium;
    font-weight: normal;
    padding-left: 8px;
    padding-right: 12px;
}

div.DrugNoReduct {
    padding-left: 18px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 14pt;
    cursor: pointer;
}
div.DrugNoReduct:hover {
    background-color: #dddddd;
}
div.selected {
    background-color: #444444;
    color: #ffffff;
}

label.Litlle {
    font-size: 12pt;    
}
div.bborder {
    border-bottom: 1px solid #dddddd;
}


div.DrurRegsList {
    overflow-y: scroll;
    height: 100px;
}

div.form_drug_reg {
    font-size:12pt;
    margin-top: 4pt;
    margin-bottom: 4pt;
}

div.RegDrugOne {
    font-size: 12pt;
    padding: 2pt;
    margin: 2pt;
}
div.DateNoSuccess {
    background-color: #888888;
}
div.DateSuccess {
    background-color: #44ff44;
}

div.DrugList {    
    height: 50vh;
    overflow-y: scroll;
}


button.btn-myselect {
    border: 1px solid #cccccc;
    background-color: #ffffff;
    height: 32px;
    font-size: 12pt;
    margin: 0;
    border-radius: 5px;
    display: flex;
}
button.btn-myselect:hover {
    background-color: #cccccc;
}


span.mp_dosage {
    padding: 2pt;
    margin: 2pt;
    font-size: 12pt;
    border: 1px solid #dddddd;
    border-radius: 5px;
    color: #262c80;
    font-weight: bold;
}
span.mp_dosage button{
    padding: 0pt;
    margin: 2pt;
    font-size: 8pt;
    border: 1px solid #dddddd;
    border-radius: 5px;
    width: 20pt;
}

button.DeleteReg {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../img/Delete1.png');
    border: 0;
}
button.DeleteReg:hover {
    background-image: url('../img/Delete1-1.png');
}