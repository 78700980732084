div.OneRecipe {
  font-size: 14pt;
  width: 100%;
  border: 1px dashed #dddddd;
  border-radius: 4px;
  cursor: pointer;
}
div.OneRecipe:hover {
  background-color: #dddddd;
}


div.OneRecipe div.capt{
  border-bottom: 1px dashed #dddddd;
}
div.OneRecipe div.capt a {
  font-size: 10pt;
}

span.doctor {
  font-size: 12pt;
  font-weight: normal;
  padding-left: 5px;
  color: #105030;
}

div.OneRecipe div.id {
  display: flex;
  align-items: baseline;
  text-align: center;
  justify-content: center;
  font-size: 8pt;
  color: #888888;
}
div.OneRecipe div.id span { font-size: 12pt; font-weight: bold; color: #444444; }
div.OneRecipe div.date {  
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 12pt;
  font-weight: bold;
  color: #444444;
}
div.OneRecipe div.doctor {  
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 12pt;  
  color: #444444;
}

/*   animal  */
div.OneRecipe div.animal {
  display: flex;
  align-items: baseline;
  text-align: center;
  justify-content: center;
  font-size: 8pt;
  color: #888888;
}
div.OneRecipe div.animal span { font-size: 12pt; font-weight: bold; color: #444444; padding-left: 5px;}

div.OneRecipe div.animal span.sexM { color: #090ca8 ;}
div.OneRecipe div.animal span.sexW { color: #dd208f ;}

div.OneRecipe div.animal span.cat { color: #586ad4 ;}
div.OneRecipe div.animal span.dog { color: #4b8670 ;}
div.OneRecipe div.animal span.typeNone { color: #3f383c ;}

/*  клиент  */
div.OneRecipe div.client {
  display: flex;
  align-items: baseline;
  text-align: left;
  justify-content: left;
  font-size: 8pt;
  color: #444444;
}
div.OneRecipe div.client span { font-size: 10pt; font-weight: normal; color: #444444; padding-left: 5px;}

/*  drug  */
div.drug {
  font-size: 10pt;
  border-top: 1px dashed #dddddd;
}

div.drug span {
  font-weight: bold;
  padding-left: 4px;
}

div.Look {
  width: 23pt;
  height: 23pt;
  background-image: url('../../img/eye.png');
  background-repeat: no-repeat;  
  background-size: contain;
}
div.Look:hover {
  background-image: url('../../img/eye1.png');
}
div.Repay {
  width: 23pt;
  height: 23pt;
  background-image: url('../../img/ToArch.png');
  background-repeat: no-repeat;  
  background-size: contain;
}
div.Repay:hover {
  background-image: url('../../img/ToArch1.png');
}