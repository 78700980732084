div.MP {
   padding: 4px;
}

label.MP {
  font-size:medium;
  font-weight: normal;
  padding-left: 8px;
  padding-right: 12px;
  background-color: #888888;
  color: #ffffff;
}

strong.Empty {
  color: #888888;
  font-weight: normal;
  font-style: italic;
}


div.OneDrug {
  font-size: 10pt;
  padding: 4px;
  margin-top: 2px;
}
div.OneDrug span,select,input {
  font-size: 12pt;
}
div.OneDrug span {
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: right;  
}

label.DrugName {
  color: #078a32;
  background-color: #ffffff;
  font-size:14pt;
  margin: 4px;
  font-weight: bold;
}

div.capt_drug {
  width: 15%;
}
div.val_drug {
  width: 35%;
}
div.both_drug { width: 50%; }

input.DateNoSuccess {
  background-color: rgb(153, 14, 14);
  color: #ffffff;
}

input.Qty{
  border-color: #078a32;
  background-color: #dce9e0;
}
input.textDesc {
  font-style: italic;
  color: #143285;
}


button.DrugCalc {
  border: 0;
  border-radius: 5pt;
  background-image: url('../../img/Calc.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 40px;
  height: 40px;
}
button.DrugCalc:hover {
  background-image: url('../../img/Calc1.png');
}