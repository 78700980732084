div.TopMenu {
  text-align: center;  
  width: 100%;  
  margin: 4px;
  border-bottom: 1px solid #dddddd;
}
div.TopMenu a{
  margin: 4px;
  font-size: 12pt;
}

span.UserName {
  font-size: 8pt;
  color: #222288;
}

button.UserNameExit {
  width: 12px;
  height: 12px;
  border: 0;
  background-image: url('../img/Delete2.png');
  background-repeat: no-repeat;
  background-size: cover;
}
button.UserNameExit:hover {
  background-image: url('../img/Delete2-1.png');
}


