
div.MySelect {
    border: 1px solid #dddddd;
    position: relative;
    border-radius: 5px;
}
input.MySelectInput {
    width: 100%;
    border: 0;
    padding-left: 10pt;
}
input.new_value {
    background-color: #a1e2cd;
}

div.MySelectList {
    width: 100%;
    border: 1px solid #dddddd;
    height: 20hv;
    overflow-y: scroll;

    position: absolute;
    top: 100%;
    /*left: 0;*/
    /*right: 0;*/
    z-index: 1000;
    background-color: #ffffff;
    /*display: none;*/
    opacity: 0;
    transition: opacity 0.3s ease;
    display: none;
    border-radius: 5px;
}
div.MySelectList.show {
    opacity: 1;
    display: block;
}

ul.MySelectUL {
    width: 100%;
    height: 100%;
    padding-left: 10pt;
}

ul.MySelectUL li {
    font-size: 12pt;
    cursor: pointer;
    list-style-type: none;        
}
ul.MySelectUL li:hover {
    background-color: #4171da;
}