div.ReceipeRepay label {

  background-color: #ffffff;
  color: #444444;
  width: 100%;
  text-align: center;
  font-size: 14pt;
}


div.ReceipeRepay div.capt {
  font-size: 10pt;
}

div.ReceipeRepay div.val {
  font-size: 12pt;
  font-weight: bold;
}

div.Drug {
  font-size: 12pt;
  border-bottom: 1px dashed #dddddd;
}
div.Drug span{
  font-size: 14pt;
  font-weight: bold;
}

div.drugInfo {
  font-size: 12pt;
}
div.drugInfo span {
  font-size: 14pt;
  font-weight: bold;
}



div.ClientInfoRepay {
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 5pt;
  padding-left: 10pt;
  padding-right: 10pt;
  padding-top: 0;
  font-size: 12pt;
  color: #000000;
  background-color: #c7e8ec;
  margin-top: 4px;
}
div.ClientInfoRepay label {
  font-weight: bold;
  color: #888888;
  background-color: #c7e8ec;
}
div.ClientInfoRepay span {
  font-weight: bold;
}

div.AnimalInfoRepay {
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 5pt;
  padding-left: 10pt;
  padding-right: 10pt;
  padding-top: 0;
  font-size: 12pt;
  color: #000000;
  background-color: #dff1df;
  margin-top: 4px;
}
div.AnimalInfoRepay label {
  font-weight: bold;
  color: #888888;
  background-color: #dff1df;
}
div.AnimalInfoRepay span {
  font-weight: bold;
}


button.DrugRepay {
  width: 32px;
  height: 32px;
  border: 0;
  border-radius: 5pt;
  background-image: url('../img/RepayDrug.png');
  background-repeat: no-repeat;
  background-size: cover;
}
button.DrugRepay:hover {
  background-image: url('../img/RepayDrug1.png');
}


div.QtyRepay {
  
  font-size: 12pt;
  color: #ffffff;
  text-align: right;
  
}
div.QtyRepayHide {
  display: none;
  
}
div.QtyRepay span {
  background-color: #e67878;
  padding-left: 15pt;
  padding-right: 15pt;
}


div.DrugRepay {
  border-bottom: 1px dashed #dddddd;  
  height: 40pt;
  text-align: center;
}
div.DrugRepay span.rd_v {
  font-size: 14pt;
  color: #444444;
  font-weight: bold;
}
div.DrugRepay span.rd_c {
  font-size: 14pt;
  color: #3f9437;
  font-weight: bold
}

div.DrugRepay div.num {
  font-size: 12pt;
}

div.FullRepay {
  width: 100%;
  text-align: center;
}
div.FullRepay h1{
  color: #ee3333;
}
