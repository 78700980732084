
div.reductUser {
    font-size: 14pt;
    padding: 4pt;
    margin: 4pt;    
}

div.reductUser span {
    font-size: 12pt;
    margin-right: 20pt;
}

div.listUser {
    width: 100%;
    margin: 4pt;
}

div.OneUser {
    font-size: 12pt;
    cursor: pointer;
}
div.OneUser:hover {
    background-color: #dddddd;
}
div.OneUser div.rights {
    text-align: right;
    font-size: 10pt;
    font-style: italic;
}

div.SelectUser {
    background-color: #b4736a;
    margin-bottom: 4pt;
}
div.NewUser {
    background-color: rgb(146, 223, 159);
    margin-bottom: 4pt;
}