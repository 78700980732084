div.Lists {  
  padding: 4px;
}

label.capt {
  width: 100%;
  text-align: center;
  font-size: 14pt;
  border-bottom: 1px dashed #dddddd;
  margin-bottom: 12px;
}
label.capt1 {
  width: 100%;
  text-align: left;
  font-size: 14pt;
  border-bottom: 1px dashed #dddddd;
  margin: 4px 4px 4px 4px;
}


ul.list {
  width: 100%;
}
ul.list li {
  font-size: 14pt;
  cursor: pointer;
}
ul.list li:hover{
  background-color: #dddddd;
}
ul.list li.selected {
  background-color: #444444;
  color: #ffffff;
}


div.tableValues {
  overflow-y: auto;
  height: 50vh  
}


ul.listTable {
  width: 100%;
}
li.tableValue {
  border: 0;
  padding: 1px;
  margin: 0;
}