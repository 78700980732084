div.ReceipeList label {

  background-color: #444444;
  color: #ffffff;
  width: 100%;
  text-align: center;
  font-size: 14pt;
}


div.FiltersRecipe {
  width: 100%;
  font-size: 12pt;
  margin-top: 3pt;
}

span.filterCapt {
  font-size: 12pt;
  color: #888888;
}

span.RecipeFindCount {
  padding-right: 12px;
  font-weight: bold;
  font-size: 10pt;
}

div.recipesNavigator {
  width: 100%;
  text-align: left;
  margin-left: 20pt;
  font-size: 12pt;
}

span.PageNavigator {
  font-size: 10pt;
  cursor:pointer;
  color: #222244;
  padding-left: 5pt;
  padding-right: 5pt;
  padding-bottom: 2pt;
}
span.PageNavigator:hover {
  color: #1c4e3a;
  text-decoration: underline;
}
span.PageNavigatorSelected{
  background-color: #444444;
  color: #ffffff;
}
